@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

.meals-section {
  background-color: white;
  background-color: #aaa0b009;
  position: relative;
  z-index: -2;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: white;
    // border-radius: 30px;
    background-color: #aaa0b009;
    min-height: fit-content;
    margin: 1.25rem 0;
    padding: 2rem 0.1rem;
  }

  @include tablet {
    margin: 1rem 1rem 1rem 1.25rem;
    padding: 0rem 3rem 2.5rem 3rem;
    box-shadow:
      0 5px 10px rgba(154, 160, 185, 0.05),
      0 15px 40px rgba(166, 173, 201, 0.1);
  }

  @include desktop {
    margin: 1.5rem 1.25rem 1.25rem 1.25rem;
    padding: 0rem 2.5rem 2.5rem 2.5rem;
    box-shadow:
      0 5px 10px rgba(154, 160, 185, 0.05),
      0 15px 40px rgba(166, 173, 201, 0.2);
  }
}

.meals-section__upper {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-self: center;
  border-radius: 30px;

  @include mobile {
    width: -webkit-fill-available;
  }
}

.meals-section__header {
  color: white;
  font-family: 'Junction Light', 'Junction Bold', 'Toronto Subway',
    'Toronto Subway Bold';
  text-wrap: nowrap;
  font-weight: 800;
  border-radius: 10px;
  backdrop-filter: opacity(0.5);

  @include mobile {
    font-family: 'Toronto Subway';
    font-variant: all-small-caps;
    font-size: 2.8rem;
    line-height: 2.3rem;
    display: flex;
    align-self: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    letter-spacing: 1.5px;
    text-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  @include tablet {
    font-size: 2.5rem;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-wrap: nowrap;
  }

  @include desktop {
    font-size: 3.4rem;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-wrap: nowrap;
  }
}

.meals-section__lower {
}

.meals-section__map-link {
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: 'Toronto Subway';
  padding: 0.5rem 3rem 0.5rem 5rem;
  letter-spacing: 1.4px;
  color: white;
  // text-transform: uppercase;
  width: fit-content;
  transition: all 0.4s ease-in-out;
  border: 1px solid white;
  padding: 1rem 1.5rem;

  @include mobile {
    padding: 0.5rem 2rem 0.5rem 2rem;
    text-wrap: balance;
  }

  &:hover {
    text-decoration: underline;
    transition: all 0.4s ease-in-out;
    background-color: white;
    color: black;
  }
}
