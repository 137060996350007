@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

.filter-button {
  margin-right: 0.1rem;
  width: 120px;
  height: 3rem;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 5px;
  transition: all 0.3s ease;
  background-color: #2b2b2b;
  color: #ffffff;
  cursor: pointer;
  letter-spacing: 2px;

  &:active {
    transform: scale(0.98);
    border: none;
  }

  &:focus {
    outline: none;
  }
}

.button-container {
  display: inline-flex;
  padding-left: 1rem;
  align-items: center;

  @include mobile {
    padding-left: 0;
  }
}

.btn {
  &--All,
  &--Beds,
  &--Rooms {
    // padding:1rem;
    text-transform: uppercase;
    white-space: normal;
    text-align: center;
    // box-shadow: inset 0 0 0 2px #00000019;
    width: -webkit-fit-content;
    // border: none;
    button-decoration: none;

    @include mobile {
      // min-width: 5rem;
      padding: 0.3rem;
    }
    @include tablet {
      min-width: 9rem;
    }
    @include desktop {
      min-width: 9rem;
    }

    &:not(.selected) {
      background-color: transparent;
      border: none;

      &:hover {
        background-color: rgba(128, 128, 128, 0.118);
        border: 1.9px solid white;
        color: #f8faff;
        transition: all 0.15s ease;
      }
    }

    &.selected {
      top: 9rem;
      background-size: 100% 100%;
      border: none;
      // border: 2px solid white;
      background-position:
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px;
      background-image: radial-gradient(
          75% 75% at 50% 50%,
          #e8e8e89c 100%,
          #073aff00 100%
        ),
        repeating-radial-gradient(
          75% 75% at 238% 218%,
          #00ffff12 30%,
          #073aff14 39%
        ),
        radial-gradient(99% 99% at 109% 2%, #00c9ffff 0%, #073aff00 100%),
        radial-gradient(160% 154% at 711px -303px, #2000ffff 0%, #073affff 27%);
      color: ghostwhite;
      width: max-content;
      padding: 0.3rem;
    }
  }

  .button--icon {
    height: 1.2rem;
    color: white;
    margin-right: 0.4rem;
    display: inline;
  }

  .button-text {
    font-size: 1.4rem;
    color: white;
    position: relative;
    border-radius: 10px;
    min-width: inherit;

    @include tablet {
      font-size: 1.2rem;
    }

    &::after {
      content: '';
      position: absolute;
      height: 2px;
      // width: 100%;
      left: 0;
      bottom: 0;
      opacity: 0;
      transform: translateY(3px);
      background: white;
      transition:
        opacity 0.2s ease,
        transform 0.2s ease;
    }

    &:hover::after {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &--Filter-All {
    display: inline-flex;
  }
}

// ---------------- MOBILE FILTER BUTTONS ----------------

.mobile-filter-button {
  background-color: white;
  text-transform: uppercase;
  font-size: 1.2rem;
  width: 40dvw;
  display: flex;
  flex-direction: column;
}

// ---------------- REFACTORED (NEW UI) STYLING ----------------

.filter-capacity-type__radio {
  width: 250px;
  text-align: center;
  border-radius: 5px;
}

.filter-program-type__option {
}

.filter-popover {
  width: 30rem;
}
.filter-primary-button {
  width: 17rem;
  height: 2.5rem;
  font-size: 0.95rem;
}

.ant-select-dropdown {
  background-color: white !important;
}