@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

a:visited,
a:link,
a:active {
  text-decoration: none;
  width: fit-content;
  max-height: 15rem;
}

a:active,
a:hover {
  transition: all 0.4s ease-in-out;
  border-radius: 20px;
  transform: scale(1.01);
}

* {
  transition: font-size 0.2s ease-in-out;
}
.resources__section {
  position: relative;
  background-color: #1b2021;
  z-index: -2;

  @include mobile {
    // height: 120rem;
    position: relative;
    z-index: -2;
  }

  @include tablet {
  }

  @include desktop {
  }
}

.hidden-resources {
  opacity: 0;
  transition: opacity 1s ease;

  transform: translateY(10%);
  visibility: hidden; // Ensures the content is not interactable when hidden
  transition:
    opacity 0.5s ease,
    transform 0.5s ease,
    visibility 0s linear 0.5s;
}

.show-resources {
  opacity: 1;
  transition: opacity 3s ease;
  position: relative;
  z-index: 3;
  animation: fadeInFromBottom 2.5s ease;

  opacity: 1;
  transform: translateY(0);
  visibility: visible; // Ensures the content is interactable when visible
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
}

.resources__div {
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @include mobile {
    padding: 4rem 0;
    display: grid;
    place-items: center;
  }

  @include tablet {
    min-height: 925px;
  }

  @include desktop {
    min-height: 925px;
  }

  &-header {
    padding-top: 1rem;
    letter-spacing: 3px;
    justify-content: end;
    color: white;
    font-size: 4vw;
    font-weight: 800;
    padding-left: 1.5rem;
    text-transform: uppercase;
    font-size: 3rem;
    z-index: -1;
    font-family: 'Junction Bold';
    font-size: 4rem;
    letter-spacing: 0rem;

    @include mobile {
      font-size: 14vw;
      letter-spacing: -0.25rem;
    }
  }
}

.grid-container {
  display: grid;
  width: 90%;
  justify-items: center;

  @include mobile {
    grid-template-columns: repeat(1, minmax(40px, 200px));
    row-gap: 1.5rem;
    justify-content: center;
  }

  @include tablet {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3rem;
    // column-gap: 1rem;
    flex-basis: 1;
  }

  @include desktop {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    row-gap: 3rem;
    column-gap: 1rem;
    width: 86rem;
    justify-content: center;
  }
}

.grid__item {
  box-shadow: 0 3px 7px 0 #00000080;
  width: 15rem;
  height: 15rem;
  text-align: center;
  text-wrap: balance;
  border-radius: 17px;
  display: grid;
  place-content: space-between;
  // backdrop-filter: blur(15px);
  font: small-caption;
  position: relative;
  z-index: -1;
  background: linear-gradient(45deg, #b27474, #8789ae);
  background-size: cover;
  transition:
    transform 0.4s ease-in-out,
    box-shadow 0.4s ease-in-out;
  transform: translateZ(
    0
  ); // Promote layering for GPU acceleration - to assist in janky feel on ios

  &:hover {
    border-radius: 10px;
    border: 2px solid white;
    box-shadow: 0px 0px 10px 0 #cca6af;
    scale: (1.05);
    transition: all 0.4s ease-in-out;
  }

  @include mobile {
    padding: 1rem;
    width: 90vw;
    height: 4rem;
    border-radius: 10px;
    place-content: center;
    z-index: -1;
    position: relative;
  }

  @include tablet {
    padding: 0.1rem 0.5rem 1rem 0.5rem;
  }

  @include desktop {
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    // transform: scale(1);
    transition:
      border-radius 0.4s ease-in-out,
      background-color 0.4s ease,
      transform 0.4s ease,
      box-shadow 0.4s ease,
      scale 0.4s ease;
  }

  &-header {
    font-weight: 800;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.15rem;

    @include mobile {
      font-size: 1.05rem;
    }

    @include tablet {
      padding-top: 1rem;
    }

    @include desktop {
      padding-top: 1rem;
    }
  }

  &-text {
    font-size: 1rem;
    color: #d9d9d9;
    margin-top: 1.4rem;
    align-items: end;
    place-self: self-end;
    letter-spacing: 0.2px;
    word-wrap: normal;
    line-height: 1.3rem;
    opacity: 1;

    @include mobile {
      display: none;
    }

    @include tablet {
      display: contents;
    }

    @include desktop {
      display: contents;
      margin: 10rem;

      &:hover {
        color: white;
      }
    }
  }
}

// .show-resources {
//   position: relative;
//   z-index: 3;
//   transition: opacity 0.2s ease, transform 0.5s ease;
// }

@keyframes fadeInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
  }
}
