@use './abstracts' as *;
@use './fonts' as *;
@use './variables' as *;

* {
  margin: 0;
  padding: 0;
}

*p {
  font-family: 'Avenir Next', 'League Spartan Regular', 'Verdana', 'Arial',
    'system-ui';
  font-style: normal;
  margin: 0;
  padding: 0;
}

*h1,
*h2,
*h3,
*h4,
*h5,
*h6 {
  font-family: 'Avenir Next Bold', 'Verdana', 'Arial', 'system-ui';
  font-style: normal;
  margin: 0;
  padding: 0;
}

*a {
  text-decoration: none;
  color: inherit;
}
