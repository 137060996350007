@import './partials/reset';

@media (prefers-reduced-motion) {
  .hidden {
    transition: none;
    animation: none;
  }
}

body {
  background-color: #0f0f0f;
  color: white;
  height: 100%;
  width: 100%;
}
