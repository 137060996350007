@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

.hidden-about-us {
  opacity: 0;
  transition: opacity 1s ease;

  transform: translateY(10%);
  visibility: hidden; // Ensures the content is not interactable when hidden
  transition:
    opacity 0.5s ease,
    transform 0.5s ease,
    visibility 0s linear 0.5s;
}

.show-about-us {
  transition: opacity 3s ease;
  position: relative;
  z-index: 3;
  animation: slideInFromLeft 5s ease;

  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  transition:
    opacity 0.5s ease,
    transform s ease;
}

.mission__section {
  // background-color: black;
  // background: linear-gradient(180deg, #aaa0b0 0%, #cca6af 95%,) center / cover no-repeat fixed; // alt background gradient (pink/purple)
  // background: linear-gradient(180deg, #EDF1F4 0%, #C3CBDC 95%) center / cover no-repeat fixed; // alt background gradient (white/blue)
  // background-image: linear-gradient(315deg, #f1f2f6 0%, #c9c6c6 84%); // alt background gradient (beige/white)
  // background-image: linear-gradient(45deg, #f1f2f6 8%, #8693ab 84%);
  // background-image: linear-gradient(360deg, #0f0f0f 75%, #222222 76%, #000000ed 76%);
  // background-image: linear-gradient(360deg, #0f0f0f 75%,  #0f0f0f 75%); // new dark background
  // background-color: #0f0f0f; // temp dark background
  // background-image: radial-gradient(circle at 50% 39%, #0f0f0f 59%, #ffffff 43%); // spotlight styling effect -- for future implementation
  // background-image: radial-gradient(circle at 50% 60%, #ffffff 55%, #161616 45%); // more refined spotlight styling effect -- for future implementation
  // background-image: radial-gradient(circle at 48% -50%, #f1f2f6 0%, #8693ab 54%);
  // background-image: radial-gradient(circle at 30% 40%, #b4af9b 30%, #0f0f0f 69%); // looks like the sun in space

  // background-image: radial-gradient(circle at 100% 270%, #b4af9b 69%, #0f0f0f 69%);
  // background-image: radial-gradient(circle at 50% 200%, #b4af9b 69%, #681c1c 43%, #0f0f0f 10%);
  // background: linear-gradient(45deg, #b27474, #8789ae);
  // background-image: radial-gradient(circle at 50% 700%, #ffffff 90%, #0f0f0f 54%);
  // background-image: url(../../assets/images/wave-haikei1.svg);
  // background-image: url(../../assets/images/blob-scene-haikei2.svg);
  // background-size: cover;

  // background-attachment: fixed;
  // padding: 1rem;
  // height: 100vh;
  // z-index: -1000;
  // position: relative;
  // flex-direction: column;
  // display: flex;
  // place-content: center;
  // animation: fadeIn 1s forwards;

  background-image: url(../../assets/images/wave-haikei1.svg);
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -2;
  position: relative;

  @include mobile {
    background-position: bottom;
  }

  @include tablet {
    background-position: bottom;
  }

  @include desktop {
    background-attachment: fixed;
    background-position: center;
  }
}

.mission__div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  &-inner {
    display: flex;
    justify-content: space-evenly;
    height: 90vh;
    width: 100vw;
    padding: 2rem 0;

    @include mobile {
      flex-direction: column;
      height: 100%;
      justify-content: normal;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    @include tablet {
      flex-direction: column;
      height: 100%;
      justify-content: normal;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  &-inner-container-1 {
    display: flex;
    justify-content: center;
    flex-direction: column;

    // background-image: radial-gradient(circle at 50% 200%, #ecc423 59%, #681c1c 43%);
    // background-image: radial-gradient(circle at 0% 0%, #ecc423 20%, #681c1c 60%); //red yellow sunset gradient
    // background-image: radial-gradient(circle at 50% 200%, #ecc423 69%, #681c1c 43%, #0f0f0f 10%); //black page color, yellow fill
    // background-image: radial-gradient(circle at 50% 200%, #b4af9b 69%, #681c1c 43%, #0f0f0f 10%);

    background-attachment: fixed;
    gap: 2rem;

    @include mobile {
      align-self: center;
    }

    @include tablet {
      margin: 1rem;
    }

    @include desktop {
      width: 40rem;
    }
  }

  &-inner-container-2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-attachment: fixed;

    @include mobile {
      width: 100%;
    }

    @include tablet {
      width: 80%;
      align-self: center;
    }

    @include desktop {
      width: 40rem;
    }
  }

  &-h2 {
    color: white;
    font-size: 2rem;
    display: flex;
    width: auto;
    margin: 0 auto;
    letter-spacing: 5px;
    font-variant: all-petite-caps;
    font-family: 'Toronto Subway Bold';
    text-align: center;
    width: 92%;
    font-size: 1.9rem;

    @include mobile {
      font-size: 1.7rem;
      width: 20rem;
    }

    @include tablet {
      font-size: 2rem;
      width: 23rem;
    }

    @include desktop {
      width: 30rem;
    }
  }

  &-text {
    color: #ffffff;
    // height: 12.25rem;
    padding: auto;
    width: 97%;
    // font-style: italic;
    // letter-spacing: -0.1px;
    font-size: 0.95rem;

    @include mobile {
      margin-left: 0.5rem;
      // padding-bottom: 1rem;
    }

    @include tablet {
      padding: 0 1rem;
    }

    @include desktop {
      padding: 0 1rem;
    }
  }

  &-logo-container {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
    flex-direction: column;
    justify-content: space-evenly;
    height: 65%;
    align-self: center;
  }

  &-logo {
    align-self: center;

    @include mobile {
      height: 11rem;
    }

    @include tablet {
      height: 14rem;
    }

    @include desktop {
      height: 16rem;
    }
  }
}

.mission__div-header {
  display: inline;
  flex-direction: row;
  min-width: fit-content;
  padding-bottom: 1rem;
  font-size: 2rem;
  color: white;
  align-items: center;
  font-variant: all-petite-caps;
  font-family: 'Toronto Subway', 'Junction Light', 'Junction Bold';
  // text-align: center;
  // align-self: normal;
  // width: 34.25rem;
  width: 100%;
}

.mission-content__div {
  display: inline-flex;
  flex-direction: column;
  font-family: 'Avenir Next', 'Junction Light', 'Junction Bold';
  word-spacing: 1.5px;
  font-size: 1.1rem;
  line-height: 1.6rem;
  align-items: center;
  justify-content: center;
  // background-color: transparent;
  border-radius: 0 20px 0 20px;
  // border: 3px solid #ffffff;
  min-width: fit-content;
  padding: 1.39rem 2.78rem;
  place-items: flex-start;
}

.slide-left {
  animation: slideInFromRight 1.5s forwards;
}

.slide-right {
  animation: slideInFromLeft 1.5s forwards;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.link {
  font-style: italic;
  font-family: 'Avenir Next';
  color: rgb(255, 175, 163);
  text-decoration-line: underline;
  font-weight: 600;
}

@media (prefers-reduced-motion) {
  .hidden {
    transition: none;
    animation: none;
  }
}

.mission__div-item {
  width: 100%;
  border-bottom: 1px solid white;
  flex-direction: row;
  display: flex;
  cursor: pointer;
  margin-bottom: 2rem;
  padding: 1rem 1rem 1rem 0;
}
.mission__item-button {
  min-width: fit-content;
  background-color: transparent;
  color: white;
  border: none;
  display: inline-flex;
  justify-content: space-between;
  cursor: pointer;
}

#one,
#two,
#three {
  color: white;
  font-size: 3rem;
  align-self: center;
  position: absolute;
  top: 0;
  left: 0;
}

.custom-collapse .ant-collapse-content {
  color: white;
  font-family: 'League Spartan Regular';
  font-size: 1rem;
}

.about-us-collapse .ant-collapse-header-text {
  color: white;
  font-family: 'DT Black';
  text-align: left;

  @include mobile {
    font-size: 0.9rem;
  }

  @include tablet {
    font-size: 1.15rem;
    padding: 0rem 0.25rem;
  }

  @include desktop {
    font-size: 1.32rem;
    padding: 0rem 0.25rem;
  }
}

.ant-collape-header {
  padding: 0;
}

.ant-collapse-content-box {
  background-color: #20242c;
}

.custom-collapse .ant-collapse-expand-icon {
  // targets expand icon (arrow)
  color: white;
  order: 2;
}

.about-us-collapse {
  background-color: #20242c;
  place-self: center;
  padding: 0.25rem 0.15rem;

  @include mobile {
    width: 93vw;
  }

  @include tablet {
    width: 86%;
  }

  @include desktop {
    width: 96%;
  }
}

.ant-float-btn-default {
  background-color: #ffffffe0;
  transition: background-color 0.2s;
  position: absolute;
}

.about__ul {
  padding: 1rem 0;
}

.about__list-item {
  display: list-item;
  list-style-type: disc;
  margin-bottom: -0.5rem;
}
