@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

.footer {
  // background-color: #8893b5;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  z-index: -2;
  position: relative;
  background-color: black;
  height: 50vh;

  @include mobile {
    height: 30rem;
    padding: 1rem;
  }

  @include tablet {
    height: 30rem;
    padding: 2rem;
  }

  @include desktop {
    height: 30rem;
    padding: 2rem;
  }
}

// ----------------- LEFT FOOTER -----------------

.footer-left {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  // padding: 1rem;
  font-size: 1rem;
  color: rgb(255 255 255 / 30%);
  background-color: #262626;
  justify-content: flex-start;
  width: 33%;
  background-color: black;
  gap: 2rem;

  @include mobile {
    width: fit-content;
  }
}

.footer-brand {
  font-size: 2.4rem;
  display: flex;
  justify-content: flex-start;
  color: white;
  font-family: 'Toronto Subway Bold';

  @include mobile {
    font-size: 1.75rem;
    gap: 1rem;
  }
}

.footer__list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.footer-list-ul {
  display: flex;
  justify-content: space-between;
}

.footer__item {
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: 'Toronto Subway';
  padding: 1rem 2rem 0.5rem 0;
  letter-spacing: 1.4px;
  color: white;
  text-transform: uppercase;
  width: max-content;
  transition: all 0.4s ease-in-out;

  @include mobile {
    font-size: 1.1rem;

    &:active {
      transition: all 0.4s ease-in-out;
      color: #cca6af;
      text-decoration: underline;
    }
  }

  @include tablet {
    font-size: 1.39rem;

    &:active {
      transition: all 0.4s ease-in-out;
      color: #cca6af;
      text-decoration: underline;
    }
  }

  @include desktop {
    font-size: 1.39rem;
    // padding: 0.5rem 1rem 0.6rem 30rem;

    &:hover {
      transition: all 0.5s ease;
      text-decoration: underline;
      text-shadow: 0 2px 1px rgba(229, 228, 255, 0.2);
    }
  }
}

.legal-item {
  font-size: 0.9rem;
  padding: 0 0 0.75rem 0;

  &:active {
    transition: all 0.4s ease-in-out;
    color: #cca6af;
    text-decoration: underline;
  }

  &:hover {
    transition: all 0.5s ease;
    text-decoration: underline;
  }
}

// ----------------- MIDDLE FOOTER -----------------

.footer-middle {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  font-size: 1rem;
  font-style: italic;
  color: rgb(255 255 255 / 30%);
  background-color: #262626;
  align-items: center;
  width: 33%;
  background-color: black;
  align-self: center;

  @include mobile {
    display: none;
  }
  @include tablet {
    width: fit-content;
  }
}

.footer-div__slogan-h2 {
  color: white;
  font-size: 2rem;
  display: flex;
  width: auto;
  margin: 0 auto;
  letter-spacing: 5px;
  font-variant: all-petite-caps;
  font-family: 'Toronto Subway Bold';
  text-align: center;
  place-content: center;
  background-color: black;

  @include mobile {
    font-size: 1.6rem;
  }

  @include tablet {
    font-size: 1.5rem;
  }

  @include desktop {
  }
}

.footer__logo-svg {
  height: auto;

  @include tablet {
    width: 10rem;
  }

  @include desktop {
    width: 15rem;
  }
}

// ----------------- RIGHT FOOTER -----------------

.footer-right {
  display: inline-flex;
  flex-direction: column;
  padding: 0.5rem 1rem 1rem 1rem;
  width: 33%;
  align-items: flex-end;
  background-color: black;
  justify-content: space-between;
  text-align: RIGHT;

  @include mobile {
    padding: 0.3rem;
  }

  @include tablet {
  }
}

.footer-right__social-links {
  margin: 0 10px;
  font-size: 1.25rem;
  color: white;
  gap: 1rem;
  text-decoration: none;
}

.footer-right__socials {
  @include mobile {
    scale: 1.5;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  @include tablet {
    scale: 1.3;
  }

  @include desktop {
    scale: 1.3;
  }
}

.footer-right__social-links:hover {
  color: #0073b1;
}

.footer-right__social-links:hover .fa-github {
  color: #6e5494;
  transition: all 0.3s ease;
}

.footer-bottom {
  display: flex;
  place-content: center;
  background-color: black;
  z-index: -3;
  position: relative;

  @include mobile {
    position: relative;
    z-index: -2;
  }
}

// ----------------- BOTTOM FOOTER -----------------

.copyright-disclaimer {
  display: flex;
  text-align: center;
  width: max-content;
  font-variant: all-small-caps;
  color: #ffffff;
  margin-bottom: 1rem;
}
