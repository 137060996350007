@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

body,
html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

li {
  list-style: none;
}

.header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  justify-content: space-between;
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  transform: translateY(-100%); // Start hidden
  width: 100%;
  background-color: #0f0f0f;
  padding: 1;
}

.header__container {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1001;
  @include mobile {
    justify-content: space-between;
    padding: 0.2rem 1rem 0rem 1rem;
  }

  @include tablet {
    padding: 1.75rem 1rem 0.5rem 1rem;
    align-items: end;
  }

  @include desktop {
    padding: 1.75rem 1.5rem 0.5rem 1.5rem;
    align-items: end;
  }
}

.header.hidden {
  transform: translateY(-100%);
  align-items: end;
}

.header.visible {
  transform: translateY(0);
  transition: transform 0.35s ease-in-out;
  align-items: end;

  @include mobile {
    width: 100%;
  }
}

.header-brand {
  display: inline;
  color: white;
  opacity: 0;
  animation: navFadeIn 3s ease-out 3s forwards;
  font-family: 'Toronto Subway Bold';
  letter-spacing: 1.5px;

  @include mobile {
    font-size: 2.3rem;
  }

  @include tablet {
    font-size: 2.8rem;
  }

  @include desktop {
    font-size: 2.8rem;
  }
}

// --------------- Navbar Section ---------------
.navbar {
  // background-color: white;
  height: fit-content;
  min-height: 6rem;
  pointer-events: none;
  position: relative;

  @include mobile {
    position: fixed;
    top: 0;

    z-index: 1000;
  }

  @include tablet {
    position: fixed;
    top: 0;
    margin-bottom: -1000rem;
    z-index: 1000;
  }

  @include desktop {
    position: fixed;
    top: 5%;
    overflow: hidden; // enables overflow scrolling block for menu
    width: 100%;
    z-index: 1000;
  }

  &__div {
    display: flex;
    align-self: left;
    justify-content: end;

    &-ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      top: 6rem;
      left: 0;
      background-color: white;
      pointer-events: auto;

      // enables menu on desktop
      @include desktop {
        // transform: translateY(-100%);
        transition: transform 0.3s ease-in-out;
      }
    }

    &-li {
      text-align: center;
      text-decoration: none;
      display: flex;
      align-items: center;
      font-family: 'Toronto Subway';
      padding: 0.5rem 2rem 0.5rem 5rem;
      letter-spacing: 1.4px;
      color: white;
      text-transform: uppercase;
      width: max-content;
      transition: all 0.4s ease-in-out;

      @include mobile {
        font-size: 1.3rem;

        &:active {
          text-decoration: underline;
        }
      }

      @include tablet {
        font-size: 1.4rem;

        &:active {
          transition: all 0.4s ease-in-out;
          background-color: white;
          color: #cca6af;
        }
      }

      @include desktop {
        font-size: 1.7rem;
        // padding: 0.5rem 1rem 0.6rem 30rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.coming-soon {
  &:hover {
    text-decoration: none;
  }
}

.coming-soon-text {
  font-variant-caps: all-small-caps;
}
.navbar__socials-container {
  display: flex;
  gap: 1.25rem;
  position: relative;
  padding-right: 3.5rem;

  @include mobile {
    top: 5rem;
    gap: 1.9rem;
    padding-right: 2.4rem;
  }

  @include tablet {
    top: 7rem;
  }

  @include desktop {
    top: 10rem;
  }
}

.navbar__socials-item {
  display: flex;
  color: white;

  @include mobile {
    font-size: 1.65rem;
  }

  @include tablet {
    font-size: 1.5rem;
  }

  @include desktop {
    font-size: 1.75rem;
  }
}
.navbar__socials-link {
  display: flex;
}

.navbar__socials-item:hover {
  color: #0073b1;
}

.navbar__socials-item:hover .fa-github {
  color: #6e5494;
  transition: all 0.3s ease;
}

// --------------- BURGER MENU STYLING ---------------

.navbar__burger-bar {
  pointer-events: auto;
  display: block;
  border-radius: 5px;
  border: 3px solid white;
  position: relative;

  @include mobile {
    width: 2.5rem;
    // margin-right: 1.5rem ;
    margin: 8.5px auto;
    -webkit-transition: all 0.3s ease-in-out;
  }

  @include tablet {
    width: 2.5rem;
    margin: 8.5px auto;
    -webkit-transition: all 0.3s ease-in-out;
  }

  @include desktop {
    width: 2.5rem;
    margin: 9px auto;
    -webkit-transition: all 0.3s ease-in-out;
  }
}

// --------------- BURGER MENU STYLING ---------------
.menu {
  cursor: pointer;
  pointer-events: auto;
  overflow: hidden;
  top: -95%;
}

//--------------- BURGER MENU --OPEN/CLOSE ANIMATION ---------------

// enables new corner nav on all screens
@media screen {
  .menu {
    display: block;
    animation: navFadeIn 4s ease-out 3s forwards;
    // padding-right: 2rem;
    // margin-top: 1.5rem;
    opacity: 0;
    // margin: 0.8rem 1.5rem 1.5rem 1rem;
  }

  // Open/Close Animation
  @include mobile {
    .menu.active .bar:nth-child(2) {
      opacity: 0;
    }
    .menu.active .bar:nth-child(1) {
      transform: translateY(14px) rotate(45deg);
      background-color: white;
    }
    .menu.active .bar:nth-child(3) {
      transform: translateY(-15px) rotate(-45deg);
      background-color: white;
    }
  }

  @include tablet {
    .menu.active .bar:nth-child(2) {
      opacity: 0;
    }
    .menu.active .bar:nth-child(1) {
      transform: translateY(14px) rotate(45deg);
      background-color: white;
    }
    .menu.active .bar:nth-child(3) {
      transform: translateY(-15px) rotate(-45deg);
      background-color: white;
    }
  }

  @include desktop {
    .menu.active .bar:nth-child(2) {
      opacity: 0;
    }
    .menu.active .bar:nth-child(1) {
      transform: translateY(18px) rotate(45deg);
      background-color: white;
    }
    .menu.active .bar:nth-child(3) {
      transform: translateY(-11.5px) rotate(-45deg);
      background-color: white;
    }
  }

  .navMenu {
    position: fixed;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.24s ease-in-out; // main menu close speed
    align-items: flex-end;
    top: -95%;
    background-color: black;
  }

  // enables the navMenu to fade in
  .navMenu.active {
    visibility: visible;
    left: 0%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #0f0f0f;
    opacity: 1;
    -webkit-transition: all 0.2s ease-in-out; // main menu drop down speed
    align-items: flex-end;
    height: 100vh;

    @include mobile {
      top: 10%;
    }

    @include tablet {
      top: 100%;
    }

    @include desktop {
      top: 100%;
    }
  }
}

.active-menu__fullscreen {
  height: 100%;

  @include mobile {
    scale: 0.75;
    padding-bottom: 0.5rem;
  }

  @include tablet {
    scale: 0.95;
  }
}

// AboutUs Section --SafeHavenTO header underline
#aboutUs {
}

// Resources Section --Resources header underline
#resources {
  border-bottom: 6px solid #5870a9;
}

@keyframes navFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
