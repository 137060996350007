@use '../../../partials/abstracts' as *;
@use '../../../partials/fonts' as *;
@use '../../../partials/global' as *;
@use '../../../partials/variables' as *;

.terms-of-use {
  font-family: Arial, sans-serif;
  color: #333;
  border-radius: 8px;
  margin: 7rem 11rem 11rem 11rem;
  padding: 5rem;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: -1;

  @include mobile {
    margin: 7rem 1rem 11rem 1rem;
    padding: 0.5rem;
    font-size: 1.4rem;
  }

  @include tablet {
    margin: 7rem 1.5rem 11rem 1.5rem;
    padding: 1rem;
    font-size: 1.4rem;
  }

  .terms-header {
    @include mobile {
      font-size: 1.4rem;
    }
    font-size: 2.6rem;
    color: white;
    margin-bottom: 10px;
  }

  .terms-last-updated {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }

  .terms-section-header {
    @include mobile {
      font-size: 0.9rem;
    }

    @include tablet {
      font-size: 0.9rem;
    }
    font-size: 20px;
    color: white;
    margin-bottom: 10px;
  }

  .terms-paragraph {
    @include mobile {
      font-size: 0.9rem;
    }

    @include tablet {
      font-size: 0.9rem;
    }
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 10px;
  }

  .terms-list {
    list-style-type: disc;
    padding-left: 40px;
    margin-bottom: 20px;

    .terms-list-item {
      font-size: 16px;
      margin-bottom: 5px;
      list-style-type: disc;
    }
  }

  .terms-link {
    color: #0066cc;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.toggle-all-button {
  margin-bottom: 1rem;
  margin-left: 0.25rem;
  width: 10rem;

  @include mobile {
  }
}
