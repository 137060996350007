@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

.meals-banner-container {
  width: 100%;
  background-color: #ffcc00;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
  color: black;
  position: relative;
  z-index: -100;
  overflow: hidden;
  justify-content: space-around;

  @include mobile {
    padding: 0rem 0.77rem;
  }

  @include tablet {
    padding: 0.5rem;
  }
}

.meals-banner-header {
  cursor: pointer;
  background-color: #ffcc00;
  padding: 0.25rem 1.5rem;
  border-bottom: 1px solid #000;
  transition: all 0.3s ease;

  &:hover {
    // filter: saturate(0.5);
    transition: all 0.3s ease;
  }
}

.meals-banner-subheader {
  font-size: 0.75rem;
}

.meals-banner-content {
  font-family: 'League Spartan ExtraLight';
  display: inline-block;
  line-height: 1rem;
  font-size: 0.75rem;
  text-wrap: balance;
  padding: 1rem;
  align-items: center;
  display: flex;
  gap: 2rem;

  @include mobile {
    line-height: 0.9rem;
    font-size: 0.9rem;
    padding: 1rem 1rem;
  }

  @include tablet {
    line-height: 0.9rem;
    font-size: 0.85rem;
    padding: 1rem 15vw;
  }

  @include desktop {
    font-size: 0.95rem;
    padding: 1rem 25rem;
  }
}

.meals-banner-text {

}

.meals-banner-line-1 {
  margin-bottom: 0.5rem;
  border-bottom: 2px solid black;
  padding-bottom: 0.25rem;
  font-family: 'League Spartan Light';
  font-style: italic;

  @include mobile {
    font-size: 0.8rem;
  }

  @include tablet {
    font-size: 0.8rem;
  }

  @include desktop {
    font-size: 1rem;
  }
}

.meals-banner-line-2 {
  font-family: 'League Spartan ExtraLight';

  @include mobile {
    font-size: 0.85rem;
  }

  @include tablet {
    font-size: 0.8rem;
  }

  @include desktop {
    font-size: 1rem;
  }
}

.phone-link {
  color: #0000ee;
  text-decoration: underline;

  &:hover {
    color: #551a8b;
    text-decoration: underline;
  }
}
