@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

.shelter-map-container {
  width: 100vw;
  height: 100vh;
  position: relative;
}
