//Mixins

@mixin mobile {
  @media screen and (min-width: 320px) and (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 768px) and (max-width: 1279px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

// ======================= Hover Effect =======================

@mixin hover-effect($background-color, $border-radius: 10px) {
  &:hover {
    color: white;
    background-color: $background-color;
    border-radius: $border-radius;
    transition: all 0.4s ease-in-out;
  }
}