@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

.mealsMap-section {
  display: flex;
  position: relative;
  height: 90vh;
  width: 100vw;
  z-index: -1;

  // @include mobile {
  //   height: 75vh;
  //   width: 99vw;
  // }

  // @include tablet {
  //   height: 75vh;
  //   width: 80vw;
  // }

  // @include desktop {
  //   height: 100vh;
  //   width: 100vw;
  // }
}

.drawer {
  .ant-drawer-content {
    background-color: #181818;
    color: white;
    font-family: 'Toronto Subway', 'Junction Light', 'Junction Bold';
  }

  .ant-drawer-header {
    background-color: #181818;
    color: white;
    font-family: 'Junction Bold', 'Toronto Subway Bold';
    padding-top: 2rem;
    // padding: 1rem 0;

    // @include mobile {
    //   padding: 2rem 0.5rem;

    // }
  }

  .ant-drawer-title {
    font-size: 1.5rem;
    font-weight: 800;
    text-align: center;
    color: white;
    font-family: 'Toronto Subway';
    letter-spacing: 1px;
    text-transform: uppercase;
    text-wrap: balance;
    line-height: 1.75rem;

    @include mobile {
      font-size: 1.3rem;
    }
  }

  .ant-drawer-body {
    padding: 1rem;
    background-color: #181818;
    color: white;
  }

  .ant-drawer-close {
    // color: white;
    // font-size: 2rem;
    // margin: 1rem;
    // width: 50px;
    // padding: 1rem;

    color: white;
    font-size: 2rem;
    width: 3rem;
    height: 3rem;
    border: 1px solid white;
    border-radius: 50px;
    padding: 0.8rem;

    &:hover {
      background-color: white;
      color: #181818;
      border-radius: 50px;
    }

    @include mobile {
      margin: 0rem;
    }
  }

  h2 {
    color: white;
    font-family: 'Junction Bold', 'Toronto Subway Bold';
  }

  .popup__button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;

    .ant-btn {
      background-color: #5271b3;
      color: #ffffff;
      border: 1px solid #5271b3;
      border-radius: 5px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.9rem;
      transition: all 0.3s ease;

      &:hover {
        background-color: darken(#5271b3, 15%);
        transform: translateY(-2px);
      }
    }
  }
}

// ----------------------- COLLAPSE 1 (C1) -----------------------

.mealsMap__C1--DropIn-Schedule {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: 'Avenir Next';
  font-weight: 400;
  line-height: 1.5rem;
  // font-size: 1.15rem;
  text-transform: capitalize;
}
.mealsMap__C1--Weekday-Div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // gap: 14%;
}

.mealsMap__C1--Day-Container {
  display: flex;
}

.mealsMap__C1--Day {
  text-transform: capitalize;
  // width: 50%;

  font-family: 'DT Black';
  text-align: center;
  align-self: center;
  font-size: 1rem;
  color: white;

  @include mobile {
    font-size: 0.85rem;
  }
}

.mealsMap__C1--Meal-Info-Container {
  text-align: left;
}

.mealsMap__C1--Meal-Text {
  // padding: 11px 8px 11px 64px;
  text-transform: capitalize;
  width: 100%;
  color: white;
  font-size: 1rem;
  text-align: end;
  text-wrap: balance;

  @include mobile {
    font-size: 0.8rem;
  }
}

.mealsMap__C1--Hours {
  // padding: 11px 8px 11px 64px;
}

// ----------------------- COLLAPSE 2 (C2) -----------------------

.mealsMap__C2--Weekday-Div {
  display: flex;
  gap: 3rem;
  margin: 1rem;
  line-height: 18px;
  width: 100%;
}

.mealsMap__C2--Day {
  // padding: 11px 8px 11px 64px;
  text-transform: capitalize;
  width: 31%;
  font-size: 0.85rem;
  font-family: 'DT Black';
  color: white;
}

.mealsMap__C2--Hours {
  // padding: 11px 8px 11px 64px;
  text-align: start;
  display: flex;
  justify-content: start;
  color: white;
  font-size: 1rem;
}

.weekday-seperator-container {
  display: flex;
  flex-direction: row;
}
.weekday-seperator {
  display: flex;
}

.mealsMap-collapse {
  width: 100%;
}

.mealsMap-collapse-panel {
  width: 100%;
  padding: 0.3rem 0.5rem !important;

  background-color: #20242c;
  place-self: center;
  border-radius: 10px 10px 0 0;
}

.ant-collapse-header {
  letter-spacing: 1px;
  text-align: center;
  // border-bottom: 2.5px solid white;
}

// .popup__button-directions {
//   text-transform: uppercase;
//   white-space: normal;
//   text-align: center;
//   padding: 0.5rem;
//   border: 1px solid #727272;
//   transition: all 0.3s ease;
//   border-radius: 5px;
//   color: white;
//   background-color: #232f27;
//   cursor: pointer;
//   width: 100%;
//   text-align: -webkit-center;
//   display: flex;
//   justify-content: center;

//   &:hover {
//     border: 1px solid rgb(59, 52, 69);
//     background-color: #2f4066;
//     color: white;
//   }
// }

.mealsMap-popup__div {
  display: flex;
  flex-direction: column;
}

.popup__button-directions-text {
  font-family: 'Avenir Next Bold', 'Verdana', 'Arial', 'system-ui';
  font-size: 1rem;
  width: 8rem;
}

.popup__button-view-details {
  text-transform: uppercase;
  white-space: normal;
  text-align: center;
  // padding: 0.5rem;
  border: 1px solid #313843;
  transition: all 0.3s ease;
  border-radius: 5px;
  color: white;
  background-color: #313843;
  cursor: pointer;
  width: 100%;
  text-align: -webkit-center;
  display: flex;
  justify-content: center;
  align-self: center;
  height: 2rem;

  &:hover {
    border: 1px solid white;
    background-color: #2f4066;
    // color: white;
    border: 1px solid #ffffffc9;
  }
}

.mapboxgl-popup-close-button {
  font-size: 2rem;
  // padding: 1rem;
  border-radius: 40px;
  // background-color: grey;
  color: white;
  place-self: end;
  width: 1.5rem;
  height: 2rem;
  display: flex;
  justify-content: end;
  place-content: end;
  width: 100%;
}

.mapboxgl-popup-content {
  // border-radius: 40px;
  box-shadow: 0 1px 2px #0000001a;
  pointer-events: auto;
  position: relative;
  animation: fadeIn 0.5s ease-out forwards;
  backdrop-filter: blur(18.5px);
  border-radius: 10px !important;
  box-shadow: 0 0 14px 5px #999999;
  display: flex;
  flex-direction: column;
  font-variant-caps: all-petite-caps;
  border-left: 20px solid #d66853;
  background-color: #212d40 !important;
  align-self: center;

  @include mobile {
    line-height: 1rem;
    width: 19rem;
  }

  @include tablet {
    padding: 1rem 2rem 1rem 2rem;
  }

  @include desktop {
    padding: 1.5rem 2rem 1.5rem 2rem;
  }
}

.drawer__upper-container {
}

.drawer__upper-text-left {
  text-align: right;
  border-left: none;
  white-space: pre-line;
  font-family: 'Avenir Next';
  margin-bottom: 1rem;
  font-variant-caps: all-petite-caps;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;

  @include mobile {
    font-size: 1rem;
  }

  @include tablet {
    font-size: 1.1rem;
  }
}

.drawer__upper-text-right {
  text-align: left;
  white-space: pre-line;
  font-family: 'Avenir Next';
  font-weight: 400;
  line-height: 1.5rem;
  // letter-spacing: 0.3px;
  font-variant-caps: all-petite-caps;

  @include mobile {
    font-size: 1rem;
  }

  @include tablet {
    font-size: 1.15rem;
  }

  @include desktop {
    font-size: 1.15rem;
  }
}

.drawer-icon {
  width: 3rem;
  height: 1.3rem;
  align-self: center;
}

.MealsMap-phone-link {
  color: #ae94fc;
  font-size: 1.2rem;
}

.MealsMap-site-link {
  color: #ae94fc;
  font-size: 0.85rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-variant: no-contextual;
}

.MealsMap-site-link:hover {
  text-decoration: underline;
}

.mapboxgl-popup {
  transition:
    transform 1s ease,
    opacity 0.3s ease;
}

.mapboxgl-popup-content {
  opacity: 0;
  transform: scale(0.9);
}

.mapboxgl-popup.mapboxgl-popup-anchor-bottom .mapboxgl-popup-content,
.mapboxgl-popup.mapboxgl-popup-anchor-top .mapboxgl-popup-content,
.mapboxgl-popup.mapboxgl-popup-anchor-left .mapboxgl-popup-content,
.mapboxgl-popup.mapboxgl-popup-anchor-right .mapboxgl-popup-content {
  opacity: 1;
  transform: scale(1);
}

.drawer-button {
  background-color: #20242c;

  &:hover {
    background-color: rgb(242, 242, 242);
    color: #34394e;
  }
}

.mealsMap-popup-button {
}
.drawer-button-text {
  // display: inline-flex;
  width: fit-content;
  display: flex;
  margin-left: 1rem;
  // padding: 0.5rem;
}

// ----------------------- NEW DESIGN -----------------------

// .timeline-container {
//   margin: 20px 0;
//   // background-color: black;
//   padding: 20px;
//   color: white;
//   overflow-x: auto;
//   white-space: nowrap;
//   position: relative;

// }

// .horizontal-timeline {
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   position: relative;

//   .ant-timeline-item-tail {
//     display: none; // Hide the vertical lines
//   }
//   .ant-timeline-item-content {
//     display: inline-block;
//     white-space: nowrap;
//     margin: 0 2rem; // Space between timeline items
//     color: white; // Ensure text is visible on dark background
//   }
//   .ant-timeline-item-head {
//     margin-right: 10px; // Adjust the spacing between markers
//   }
// }

// .horizontal-timeline::before {
//   content: '';
//   position: absolute;
//   top: 50%;
//   left: 0;
//   width: 100%;
//   height: 2px;
//   background-color: white; // Line color
//   z-index: -1;
// }

// .current-time-marker {
//   position: absolute;
//   top: 45%;
//   transform: translateY(-50%);
//   width: 16px;
//   height: 16px;
//   background-color: #1677ff; // Marker color
//   border-radius: 50%;
//   animation: pulse 2.5s infinite;
// }

// @keyframes pulse {
//   0% {
//     transform: scale(1);
//     opacity: 1;
//   }
//   50% {
//     transform: scale(1.2);
//     // opacity: 0.9;
//   }
//   100% {
//     transform: scale(1);
//     opacity: 1;
//   }
// }
