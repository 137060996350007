@use '../../../partials/abstracts' as *;
@use '../../../partials/fonts' as *;
@use '../../../partials/global' as *;
@use '../../../partials/variables' as *;

.cookie--policy {
  color: ghostwhite;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin: 7rem 5rem 11rem 5rem;
  padding: 5rem;
  font-size: 1rem; // last updated font size
  position: relative;
  z-index: -1;

  @include mobile {
    margin: 1rem 0.5rem 2.5rem 0.5rem;
    padding: 1rem;
    font-size: 0.8rem; // last updated font size
  }

  @include tablet {
    margin: 1rem 0.75rem 2.5rem 0.75rem;
    padding: 1rem;
    font-size: 0.8rem; // last updated font size
  }
}

.cookie-header {
  @include mobile {
    padding: 0 0.5rem 0 0.5rem;
    font-size: 1.4rem;
  }

  @include tablet {
    padding: 0 1.5rem 0 1.5rem;
    font-size: 1.4rem;
  }

  @include desktop {
    padding: 0 1.5rem 0 1.5rem;
    font-size: 1.5rem;
  }
}

.cookie-date {
  @include mobile {
    padding: 0 0.5rem 0 0.5rem;
    font-size: 0.8rem; // last updated font size
  }

  @include tablet {
    padding: 0 1.5rem 0 1.5rem;
    font-size: 0.8rem; // last updated font size
  }

  @include desktop {
    padding: 0 1.5rem 0 1.5rem;
    font-size: 1rem; // last updated font size
  }
}

.cookie-h2 {
  font-weight: bold;
  font-size: 1.15rem;

  @include mobile {
    font-size: 0.8rem;
  }

  @include tablet {
    font-size: 1rem;
  }
}

.cookie-h2-number {
  padding-right: 0.4rem;
}

.cookie-text {
  line-height: 1.6;
  margin-bottom: 10px;

  @include mobile {
    font-size: 0.7rem;
    padding: 0 1rem 0 1rem;
  }

  @include tablet {
    font-size: 0.8rem;
    padding: 0 1.5rem 0 1.5rem;
  }

  @include desktop {
    font-size: 1rem;
    padding: 0 2rem 0 2rem;
  }
}

.cookie-link {
  color: #0066cc;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.cookie-h3 {
  // font-weight: bold;
  font-size: 1rem;
  padding-bottom: 1rem;

  @include mobile {
    padding-left: 1rem;
    font-size: 0.8rem;
  }

  @include tablet {
    padding-left: 1.5rem;
    font-size: 0.9rem;
  }

  @include desktop {
    padding-left: 2rem;
  }
}

.cookie-ul {
  list-style-type: none;
  font-size: 1.1rem;

  @include mobile {
  }

  @include tablet {
  }

  @include desktop {
  }
}

.cookie-li {
  padding: 10px;
  border: 1px solid #ddd;
  margin-top: 5px;
}

.cookie__list-item {
  list-style: disc;
  display: list-item;
  margin-block-start: 1rem;
  margin-block-end: 1rem;

  @include mobile {
    margin-left: 1rem;
    font-size: 0.7rem;
  }

  @include tablet {
    margin-left: 1rem;
    font-size: 0.8rem;
  }

  @include desktop {
    margin: 1rem 2rem;
    font-size: 1rem;
  }
}
