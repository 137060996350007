@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

.parallax3-mobile {
  background-position: center;
  background-repeat: no-repeat;
  z-index: -3;
  position: relative;

  @include mobile {
    zoom: 0.45;
    height: 50vh;
  }

  @include tablet {
    // zoom: .45;

    height: 100vh;
  }

  @include desktop {
    height: 900px;
  }
}

.parallax3 {
  height: 1200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -2;
  position: relative;

  @include mobile {
    height: 33rem;
    position: relative;
    z-index: -2;
  }

  @include tablet {
    height: 100vh;
    background-attachment: fixed;
  }

  @include desktop {
    height: 100vh;
    background-attachment: fixed;
  }
}
