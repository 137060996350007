@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

.login-section {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 80vh;
  place-items: center;
  filter: contrast(0.8);
  position: relative;
  z-index: -100;

  border-radius: 8px;
  margin: 7rem 11rem 11rem 11rem;
  padding: 5rem;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: -1;

  @include mobile {
    margin: 7rem 1rem 11rem 1rem;
    padding: 0.5rem;
    font-size: 1.4rem;
  }

  @include tablet {
    margin: 7rem 1.5rem 11rem 1.5rem;
    padding: 1rem;
    font-size: 1.4rem;
  }
}

.login-header {
  font-family: 'Toronto Subway Bold';
}

.login-upper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
}

.login-lower {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
