@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

.sheltersMap__Container {
  height: 40vw;
  width: 30rem;
  box-shadow: #0000002d 0px 16px 48px 0px;
  animation: fadeIn 0.5s ease-out forwards;
}

.container {
  width: 50%;
  height: 30rem;
  overflow-y: auto;
  padding: 20px;
  display: inline;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 hwb(0 0% 100% / 0.2);
  background-color: white;
  box-shadow: #0000002d 0px 16px 48px 0px;
}

.marker-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.popup {
  animation: fadeIn 0.5s ease-out forwards;
}

.popup__div {
  animation: fadeIn 0.5s ease-out forwards;
  overflow: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #212d40;

  @include mobile {
  }

  @include tablet {
    // zoom: 0.7;
    width: 17rem;
  }

  @include desktop {
    // zoom: 0.7;
    width: 20rem;
  }
}

.popup__div-upper {
  display: flex;
  flex-direction: row;
  gap: 1.1rem;
}

.popup__div-left {
  width: 50%;
  animation: fadeIn 0.5s ease-out forwards;
  align-self: center;
}

.popup__div-right {
  width: 50%;
  animation: fadeIn 0.5s ease-out forwards;
  height: auto;
}

.popup__div-header {
  color: white;

  @include mobile {
    font-size: 1.1rem;
    text-align: center;
  }

  @include tablet {
    font-size: 1.25rem;
  }

  @include desktop {
    font-size: 1.25rem;
  }
}

.popup__div-subheader-1 {
  font-size: 1.05rem;
  color: #ffffff;
  color: #ffffff;
  letter-spacing: 0.04rem;
  font-family: 'Avenir Next';
  font-weight: 600;

  @include mobile {
    width: 10rem;
  }

  @include tablet {
    width: 12rem;
  }

  @include desktop {
    width: max-content;
  }
}

.popup__div-subheader-2 {
  font-size: 0.95rem;
  color: white;
  letter-spacing: 0.04rem;
  font-family: 'Avenir Next';
  font-weight: 600;
  // color: white;
  background-color: #364156;
  padding: 0.5rem;
}

.popup__div-text {
  margin-bottom: 0.6rem;
  color: white;
  font-family: 'Avenir Next';
  font-weight: 600;
  letter-spacing: 0.4px;
  background: #7d4e57;
  padding: 0.5rem;
  font-size: 0.9rem;
}

.popup__div-lower {
  width: 100%;
}

.popup__button-directions {
  text-transform: uppercase;
  white-space: normal;
  text-align: center;
  padding: 0.5rem;
  border: 1px solid #313843;
  transition: all 0.3s ease;
  border-radius: 5px;
  color: white;
  background-color: #232f27;
  cursor: pointer;
  text-align: -webkit-center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  width: -webkit-fill-available;
  display: flex;
  gap: 0.5rem;

  &:hover {
    background-color: white;
    color: #20242c;
    transform: translateY(-2px);
    transition: all 0.5s ease;
  }
}

.btn-directions-link {
}

.popup-availability {
  color: #d66853;
  font-size: 1.28rem;
  margin-left: 0.4rem;
  font-family: Avenir Next Bold;
}

.popup__button-container {
  width: 100%;
  justify-content: center;
}

.popup__button-directions-text {
  font-family: 'Avenir Next Bold', 'Verdana', 'Arial', 'system-ui';
  font-size: 1rem;
  width: 10rem;

  &:hover {
    color: #232f27;
  }
}

.directions-icon {
  height: 0.9rem;
  color: white;
  margin-right: 0.4rem;
  display: inline;

  @include mobile {
    height: 0.7rem;
  }
}

.location-icon {
  // background-size: cover;
  // background-color: $pastel-grey;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
  animation-duration: 2s;
  transform-origin: bottom;
  animation-name: fadeIn;
  animation-timing-function: ease;

  &:hover {
    // animation: fadeIn 0.5s ease-out forwards;
    animation-timing-function: ease;
  }
}

.mapboxgl-popup-close-button {
  font-size: 2rem;
  // padding: 1rem;
  border-radius: 40px;
  // background-color: grey;
  color: white;
  place-self: end;
  width: 1.5rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  place-content: center;
  width: 100%;
}

.mapboxgl-popup-anchor-bottom-left {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.mapboxgl-popup-content {
  // border-radius: 40px;
  box-shadow: 0 1px 2px #0000001a;
  pointer-events: auto;
  position: relative;
  animation: fadeIn 0.5s ease-out forwards;
  backdrop-filter: blur(18.5px);
  border-radius: 10px !important;
  box-shadow: 0 0 14px 5px #999999;
  display: flex;
  flex-direction: column;
  font-variant-caps: all-petite-caps;
  border-left: 20px solid #d66853;
  background-color: #212d40 !important;
  align-self: center;

  @include mobile {
    line-height: 1rem;
    width: 19rem;
    zoom: 0.9;
  }

  @include tablet {
    padding: 1rem 2rem 1rem 2rem;
  }

  @include desktop {
    padding: 1.5rem 2rem 1.5rem 2rem;
  }
}

.mapboxgl-map {
  box-shadow: #ffffff2d 0px 16px 48px 0px;
  animation: fadeIn 1s ease-out forwards;
}

.mapboxgl-canvas {
  width: 90%;
}

.popup-availability {
  margin-bottom: 0.7rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-35px);
  }
  100% {
    transform: translateY(0);
  }
}
