@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

.loading-message {
  animation:
    fadeIn 4s,
    pulsate 1.5s ease-in-out infinite;
  text-align: center;
  font-size: 1.5rem;
  margin-top: 5rem;
  color: white;
  display: flex;
  justify-content: center;
  font-style: italic;
  font-variant-caps: all-petite-caps;

  @include mobile {
    min-width: -webkit-fill-available;
    justify-content: center;
    height: 50vh;
  }

  @include tablet {
    width: 90vw;
    padding: 10rem;
  }
}

.loadMoreBtn {
  background-color: #5271b3;
  color: white;
  border: none;
  padding: 3rem 0rem;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
  letter-spacing: 1px;
  transition: all 0.3s ease;
  cursor: pointer;
  width: -webkit-fill-available;

  @include mobile {
    width: 100%;
  }

  @include tablet {
    width: 100%;
  }

  @include desktop {
    // margin: 0 10rem; // for OG layout testing purposes -- using pagination now anyways for new layout
  }

  &:hover {
    background-color: darken(#5271b3, 15%);
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  &-text {
    font-size: 1.2rem;

    &:hover {
      color: white;
    }
  }
}
