@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

.mealsTimeline-container {
  width: 90vw;
  scroll-behavior: smooth;
  place-self: center;
  -webkit-overflow-scrolling: touch; // Improves scrolling on iOS devices

  &::-webkit-scrollbar {
    display: none; //  hides the scrollbar
  }

  @include mobile {
  }

  @include tablet {
  }

  @include desktop {
    display: flex;
    flex-direction: row-reverse;
    // width: 100%;
    justify-content: center;
  }
}

.mealsTimeline-half {
  display: flex;
  flex-direction: column;

  @include tablet {
    width: 100%;
  }

  @include desktop {
    width: 55%;
  }
}

.mealsTimeline-live-clock {
  color: white;
  text-align: center;
  font-family: 'Toronto Subway';
  letter-spacing: 1.3px;
  line-height: 2.1rem;
  place-self: center;
  // width: 23rem;
}

.mealsTimeline-live-event-container {
  display: flex;
  text-align: center;
  justify-content: center;

  @include mobile {
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem 0;
  }

  @include tablet {
    flex-direction: row;
  }

  @include desktop {
    flex-direction: column;
  }
}

.timeline-container {
  height: 90vh;
  overflow-y: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  color: white;
  overflow-x: auto;
  white-space: nowrap;
  position: relative;

  @include mobile {
  }

  @include tablet {
    padding: 1rem;
  }

  @include desktop {
    padding: 1rem;
  }
}

.timeline-wrapper {
  position: relative;
  overflow-x: auto;
  display: flex;
  flex-direction: column;

  @include mobile {
    width: 100%;
  }

  @include tablet {
    width: 100%;
    padding: 1rem;
  }

  @include desktop {
    padding: 1rem;
    width: 100%;
  }
}

.timeline-wrapper__header {
  text-align: center;
  font-size: 1.5rem;
  font-family: 'Toronto Subway Bold';
  border-bottom: 1px solid white;
  margin-bottom: 3rem;
  padding-bottom: 1rem;

  @include desktop {
    font-size: 1.7rem;
    padding-bottom: 2rem;
  }
}

.timeline-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 14rem;
  margin: 0.21rem 0;
  gap: 1rem;
  transition: all 0.5s ease;
  row-gap: 1rem;

  @include mobile {
    gap: 0.2rem;
    padding: 0 0.5rem;
  }

  @include tablet {
    padding: 1rem;
  }

  @include desktop {
    // padding: 1rem;
  }
  &:hover {
    background-color: darken(#5271b3, 19%);
    transition: all 0.5s ease;
  }
}

.timeline-divider {
  width: 5px;
  height: 100%;
  background-color: #6232c1;
  border: none;

  @include mobile {
    margin: 7rem 1rem 7rem 1.5rem;
  }

  @include tablet {
    margin: 1rem;
  }

  @include desktop {
    margin: 0.5rem;
  }
}

.timeline-item-time {
  width: 13%;
  text-align: right;
  margin-right: 1rem;
  font-weight: bold;
  color: white;

  @include mobile {
    margin: 0rem 0.7rem 0 0;
  }
}

.timeline-item-content {
  width: 40%;
  // border-radius: 8px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: -webkit-fill-available;
  text-wrap: wrap;

  @include mobile {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }

  @include tablet {
    padding: 1.5rem;
  }

  @include desktop {
    padding: 1.5rem;
  }
}

// -------------------- DROP-IN MEALS TIMELINE ----------------------------

.mealsTimeline-upper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.mealsTimeline__meal-type {
  font-family: 'DT Black';
  font-size: 1.3rem;

  @include mobile {
    font-size: 1.15rem;
  }
}

.mealsTimeline__meal-time {
  font-family: 'Avenir Next';
  font-size: 1.1rem;
  text-align: start;
  font-weight: 600;

  @include mobile {
    font-weight: 400;
    font-size: 1rem;
  }
}

.mealsTimeline-accessibility-div {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  position: absolute;
  left: 85%;
}

.mealsTimeline-accessibility-icon {
  scale: 1.3;

  @include mobile {
    scale: 1;
  }
}

.mealsTimeline__meal-provider {
  font-family: 'Toronto Subway';
  font-size: 0.9rem;
  display: flex;
  margin-bottom: 0.3rem;
  // text-align: end;
}

.mealsTimeline__meal-address {
  display: flex;
  font-size: 0.9rem;
}

.daily-timeline-text {
  margin-left: 0.6rem;

  @include mobile {
    margin-left: 0.3rem;
  }
}

.directions-button {
  margin-top: 0.5rem;
  text-transform: uppercase;
  font-family: 'Avenir Next Bold';
  white-space: normal;
  text-align: center;
  padding: 0.5rem;
  border: 1px solid #313843;
  transition: all 0.3s ease;
  border-radius: 5px;
  color: white;
  background-color: #212d40;
  cursor: pointer;
  text-align: -webkit-center;
  flex-direction: row;
  justify-content: center;
  width: -webkit-fill-available;
  width: 100%;
  display: flex;
  gap: 0.5rem;

  &:hover {
    background-color: white;
    color: #20242c;
    transform: translateY(-2px);
    transition: all 0.5s ease;
  }

  @include mobile {
    padding: 0.2rem 0.5rem;
  }
}

// .directions-button:hover {
//   background-color: #40a9ff;
// }

.mealsTimeline-moment-h1 {
  color: white;
  text-align: center;
}

.current-event-container {
  border: 2px solid #0f5630;
  background-color: #0c4527;
  color: white;
  text-align: -webkit-center;
  text-wrap: nowrap;
}

.next-event-container {
  border: 2px solid #c79c04;
  background-color: #c79d04c2;
  color: white;
  text-align: -webkit-center;
}

.previous-event-container,
.current-event-container,
.next-event-container {
  // event card containers
  margin: 1rem;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  text-align: center;
  place-items: center;
  place-content: center;
  padding: 1rem;
  // width: 100%;
  height: 100%;

  @include mobile {
    margin: 0rem;
    padding: 0.6rem 0.5rem;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }

  @include tablet {
    align-content: baseline;
    width: 100%;
  }
}

// .previous-event-container {
//   border: 2px solid gray;
//   color: rgba(255, 255, 255, 0.5);
//   background-color: rgba(128, 128, 128, 0.1);
//   width: 25vw;

//   @include mobile {
//     width: 100%;
//     position: sticky;
//     display: none;
//   }

//   @include tablet {
//     display: none;
//   }
// }

.previous-event-header,
.current-event-header,
.next-event-header {
  // event card headers
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;

  @include mobile {
    font-size: 1rem;
    place-self: center;
    margin-bottom: 0.25rem;
  }

  @include tablet {
    font-size: 1.3rem;
  }

  @include desktop {
  }
}

.current-event-subheader,
.next-event-subheader {
  font-family: 'Avenir Next';

  @include mobile {
    font-size: 0.9rem;
  }
}

.mealsTimeline-ant-collapse {
  color: white;
  font-family: 'DT Black';
  text-align: center;

  @include mobile {
    width: 95%;
    padding: 0rem;
  }

  @include tablet {
    width: 100%;
  }
}
.mealsTimeline-ant-collapse-header {
  color: white;
  align-items: baseline;
}

.ant-collapse-item,
.ant-collapse-header {
  padding: 0.2rem !important;
}

.ant-collapse-header-text {
  // ant dropdown header text
  color: white;
  font-family: 'DT Black';
  text-align: left;
  text-transform: uppercase;
  align-items: center;
  display: flex;

  @include mobile {
    font-size: 0.8rem;
    letter-spacing: 1.6px;
    align-self: end;
  }

  @include tablet {
    font-size: 1rem;
    padding: 0rem 0.25rem;
  }

  @include desktop {
    font-size: 1.12rem;
    padding: 0rem 0.25rem;
  }
}

.ant-collapse-content,
.ant-collapse-inner,
.current-ant-collapse-inner,
.ant-collapse-content-box {
  background-color: transparent !important;
}

.current-ant-collapse-inner {
  // background: grey !important;
}

// .ant-collapse-content-box {
//   background-color: transparent;
// }

// .current- {
//   background-color: none;
// }

.collapse__title {
  width: 100%;
  text-align: center;
  margin-left: 2rem;
}

.collapse__distance {
  font-family: 'Avenir Next';
  font-size: 0.8rem;
  font-style: italic;
  text-transform: lowercase;
  justify-content: start;
  position: absolute;
  margin-left: 0.3rem;
  margin-bottom: 0.3rem;
  font-style: bold;
  font-weight: 600;
  font-family: 'League Spartan Regular';
}

.popover-alert-button {
  background-color: transparent;
  padding: 0.1rem 0.5rem;
  box-shadow: none;
  border-radius: 50px;
  box-shadow: none !important;

  &:hover {
    background: #414a5a !important;
    box-shadow: none !important;
  }
}
.popover-alert-content {
  line-height: 1rem;
  padding-left: 1rem !important;
  width: 15rem;
  text-align: center;
  filter: invert(0.09);
  text-wrap: balance;
}

.ant-popover-inner {
  box-shadow: none !important;
}

.ant-collapse-expand-icon {
  // targets expand icon (arrow)

  color: white;
  order: 2;

  @include mobile {
    padding-inline-end: 0px !important;
    padding-left: 0.5rem;
  }
}

// -------------------- LIVE MEALS -- ANT COLLAPSE INNER ----------------------------

.current-ant-collapse-inner {
  background-color: #20242c;
  color: white;
  text-wrap: wrap;
}

.mealsTimeline__in-progress-time,
.mealsTimeline__up-next-time {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-family: 'Avenir Next';
  font-weight: 600;
}

.mealsTimeline__in-progress-provider,
.mealsTimeline__up-next-provider {
  text-align: left;
  display: flex;
}

.mealsTimeline__in-progress-address,
.mealsTimeline__up-next-address {
  text-align: left;
  display: flex;
}

.MTL-collapse-live-text {
  display: flex;
  margin-left: 0.6rem;
}

// -------------------- END ----------------------------
// -------------------- MEALS TIMELINE  ----------------------------

.is-ended {
  filter: brightness(0.5);
}

.happening-now {
  border: 1px solid green;
  background-color: #0c4527;
  animation: pulse 1.5s infinite;
  border-radius: 10px;
  width: auto;

  @include mobile {
    // padding: 6rem 0.7rem;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #0c4527;
  }
  70% {
    box-shadow: 0 0 0 12px rgba(255, 165, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 165, 0, 0);
  }
}

.timeline-wrapper__header {
  text-align: center;
  font-size: 1.3rem;
  font-family: 'Toronto Subway Bold';
  border-bottom: 1px solid white;
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  cursor: pointer;

  @include desktop {
    font-size: 1.7rem;
    padding-bottom: 2rem;
  }
}

.timeline-toggle-icon {
  margin-left: 0.5rem;
}

// -------------------- END ----------------------------
