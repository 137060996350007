@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

.liveClock-container {
  width: 100%;

  @include mobile {
    line-height: 1.5rem;
  }

  @include tablet {
    display: flex;
  }
}

.liveClock-date {
  font-size: 1.5rem;

  @include mobile {
    font-size: 1.25rem;
  }
}

.liveClock-time {
  font-size: 1.4rem;
  padding: 0 1rem;
  letter-spacing: 1px;
  font-family: 'Avenir Next';

  @include mobile {
    font-size: 1.1rem;
  }
}
