@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

.detailed-view__container {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: start;
  background-color: rgb(0, 0, 0);
  padding: 0.5rem 3rem 2rem 3rem;
  border-radius: 8px;
  text-align: center;
  text-align: -webkit-auto;
  vertical-align: baseline;
  vertical-align: -webkit-baseline-middle;
  align-self: center;
  justify-items: center;
  row-gap: 0.1rem;

  @include mobile {
    height: 36vh;
    padding: 0.5rem 1rem 2rem 1rem;
  }

  @include tablet {
    padding-bottom: 3.5rem;
  }
}

.detail-row {
  display: contents;
  gap: 0.5rem;

  &__label {
    grid-column: 1;
    font-weight: bold;
    font-size: 0.5rem;
    width: 50vw;
    color: white;
    align-self: center;
    font-family: 'Avenir Next';
    white-space: pre-line;
    line-height: 1rem;
    font-variant-caps: all-petite-caps;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;

    @include mobile {
      font-size: 1rem;
      width: 37vw;
      text-align: left;
      margin-right: 0rem;
      border-right: 2px solid #6232c1;
      // margin-left: 1rem;
    }

    @include tablet {
      font-size: 1.1rem;
      padding-right: 0.6rem;
      text-align: right;
    }

    @include desktop {
      font-size: 1.3rem;
      line-height: 1.8rem;
      padding-right: 0.6rem;
      text-align: right;
    }
  }

  &__value {
    grid-column: 3;
    font-size: 1rem;
    // text-align: left;
    width: 50vw;
    color: white;
    align-self: center;
    white-space: pre-line;
    font-family: 'Avenir Next';
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.3px;
    font-variant-caps: all-petite-caps;
    text-align: left;

    @include mobile {
      // padding-left: 0.3rem;
    }

    @include tablet {
      border-left: 3px solid #6232c1;
      padding-left: 0.6rem;
    }

    @include desktop {
      font-size: 1.2rem;
      border-left: 3px solid #6232c1;
      padding-left: 0.6rem;
    }
  }
}
