@use '../../partials/abstracts' as *;
@use '../../partials/fonts' as *;
@use '../../partials/global' as *;
@use '../../partials/variables' as *;

h3 {
  display: inline;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.shelter-section-og {
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: white;
  background-color: #aaa0b009;
  position: relative;
  z-index: -1;

  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: white;
    // border-radius: 30px;
    background-color: #aaa0b009;
    min-height: fit-content;
    // margin: 1rem;
    padding-bottom: 2.5rem;
  }

  @include tablet {
    padding: 2.5rem 1.5rem 2.5rem 1.5rem;
    box-shadow:
      0 5px 10px rgba(154, 160, 185, 0.05),
      0 15px 40px rgba(166, 173, 201, 0.1);
  }

  @include desktop {
    margin: 1.25rem;
    padding: 2.5rem 2.5rem 2.5rem 2.5rem;
    box-shadow:
      0 5px 10px rgba(154, 160, 185, 0.05),
      0 15px 40px rgba(166, 173, 201, 0.1);

    border-radius: 10px;
  }
}

.shelter-section__upper-og {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-self: center;
  border-radius: 30px;

  @include mobile {
    width: -webkit-fill-available;
  }
}

.shelter-section__header-og {
  color: white;
  font-family: 'Junction Light', 'Junction Bold', 'Toronto Subway',
    'Toronto Subway Bold';
  text-wrap: nowrap;
  font-weight: 800;
  border-radius: 10px;
  backdrop-filter: opacity(0.5);

  @include mobile {
    // font-size: 2.2rem; // CULPRIT #1 - FIXED
    font-family: 'Toronto Subway Bold';
    font-variant: all-small-caps;
    font-size: 2rem;
    display: flex;
    width: 80%;
    align-self: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  @include tablet {
    font-size: 2.5rem;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-wrap: nowrap;
  }

  @include desktop {
    font-size: 3.4rem;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-wrap: nowrap;
  }
}

.shelter-section__subHeader-og {
  font-family: 'Toronto Subway', 'Junction Light', 'Junction Bold';
  font-weight: 800;
  font-size: 1.2rem;
  color: white;
  background-image: linear-gradient(170deg, #262729 9%, #313232 96%);
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid white;
  align-items: center;
  justify-content: center;

  @include mobile {
    // display: contents;      // CULPRIT #2 - fixed
    // flex-direction: column;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    font-size: 0.85rem;
    width: 95%;
    align-self: center;
  }

  @include tablet {
    text-align: center;
  }

  @include desktop {
    display: flex;
  }
}

.subHeader__upper-og {
  display: inline-flex;
  align-items: center;

  @include mobile {
    // width: max-content;
    gap: 1rem;
  }
}
.subHeader__title-og {
  width: max-content;
  display: inline-flex;

  @include mobile {
    margin-right: 0.7rem;
  }
}

.subheader__divider-og {
  height: 3rem;
  display: flex;

  @include mobile {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    height: 0rem;
  }

  @include tablet {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    height: 0rem;
  }

  @include desktop {
    margin-right: 1rem;
    margin-left: 3rem;
  }
}

.subheader__text-og {
  font-family: 'Avenir Next';
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  font-variant-caps: all-petite-caps;
  letter-spacing: 0.45px;

  @include mobile {
    display: inline-flex; // CULPRIT #3 - fixed
    font-style: italic;
    font-variant: contextual;
    max-width: 33rem;
  }

  @include tablet {
    display: inline-flex;
    font-style: italic;
  }

  @include desktop {
    display: flex;
    width: 25rem;
  }
}

// ----------------- BUTTONS -----------------

.shelter-item__actions-btn-og {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20vw;
  max-width: 100%;
  height: 2.5rem;
  font-weight: 700;
  font-size: 0.9rem;
  background-color: #5271b3;
  color: #ffffff;
  border: 1px solid #5271b3;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  // padding: 1rem;
  transition: all 0.3s ease;
  min-width: -webkit-fill-available;
  gap: 1rem;

  padding: 0.6rem 1.2rem;
  // margin-top: 1.2rem;
  width: auto;

  &:hover {
    background-color: darken(#5271b3, 15%);
    transform: translateY(-2px);
    border: 1px solid #5271b3;
  }

  @include mobile {
    // min-width: 11rem;
    min-width: 100%;
  }

  @include tablet {
    min-width: 100%;
    display: inline-flex;
    //   height: 2.5rem;
    //   font-size: 0.9rem;
  }

  @include desktop {
    max-width: 30rem;
  }
}

.btn-og {
  &--Directions-Text,
  &--Go-Here-Text {
    font-size: 0.85rem;
  }

  &--Directions-Icon,
  &--Share-Icon {
    height: 1.2rem;
    color: white;
    padding-right: 1rem;
    display: inline;
  }
}

.shelter-section__lower-og {
  display: flex;
  justify-content: space-between;
  height: 40rem;
  gap: 3rem;

  @include mobile {
    display: inline-flex;
    flex-direction: column;
    height: 130vh;
  }

  @include tablet {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 80vh;
    gap: 2rem;
  }

  @include desktop {
    gap: 20px;
  }
}

.shelter-scrollable-container-og {
  overflow: hidden visible;
  max-height: fit-content;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: 5px;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; // Improves scrolling on iOS devices

  // background-image: linear-gradient(307deg, #c3cbdc 23%, #edf1f4 66%); // SILVER WHITE GRADIENT DESIGN
  // background: conic-gradient(from 242deg at 132% -20%, #5870a9, #7d75ad, #9c7cae, #b583ac, #ca8daa, #da99a8, #e6a6a7, #eeb5a9); // BLUE / PEACH DESIGN V1
  // background: conic-gradient(from 227deg at 191% -35%, #5870a9, #7d75ad, #9c7cae, #b583ac, #ca8daa, #da99a8, #e6a6a7, #eeb5a9); // BLUE / PEACH DESIGN V2
  // background: conic-gradient(from 780deg at 64% 68%, #5870a9 0%, #7d75ad 12%, #eeb5a9 8%, #b583ac 44%, #eeb5a9 48%, #5870a9 82%, #eeb5a9 98%, #5870a9 88%, #eeb5a9 100%); // EXPLOSION DESIGN
  // background: conic-gradient(from 150deg at 49% 32%, #5870a9 0%, #7d75ad 8%, #9c7cae 23%, #b583ac 39%, #ca8daa 56%, #da99a8 73%, #e6a6a7 89%, #eeb5a9 100%); // ANGLED BOTTOM RIGHT DESIGN
  // background: conic-gradient(from 180deg at 65% 30%, #5870a9 0%, #7d75ad 14%, #9c7cae 30%, #b583ac 46%, #ca8daa 62%, #da99a8 78%, #e6a6a7 92%, #eeb5a9 100%); // VERTICAL DIVIDER DESIGN
  // background: conic-gradient(
  //   from 270deg at 59.2% 48.5%,
  //   #5870a9 0%,
  //   #7d75ad 15%,
  //   #9c7cae 30%,
  //   #b583ac 45%,
  //   #ca8daa 60%,
  //   #da99a8 75%,
  //   #e6a6a7 90%,
  //   #eeb5a9 100%
  // );
  background: #1f1b1b;
  min-width: -webkit-min-content;
  max-width: -webkit-fit-content;

  &:hover {
    transition: all 4s ease-in-out;

    //   background: conic-gradient(
    //   from 270deg at 59.2% 48.5%,
    //   #5870a9 0%,
    //   #7d75ad 15%,
    //   #9c7cae 30%,
    //   #b583ac 45%,
    //   #ca8daa 60%,
    //   #da99a8 75%,
    //   #e6a6a7 90%,
    //   #eeb5a9 100%
    // );
  }

  &::-webkit-scrollbar {
    // display: none; //  hides the scrollbar
  }

  @include mobile {
    // min-width: 30rem; // THIS ONE
    height: 100vh;
    min-width: -webkit-fill-available;
    // display: none;
  }

  @include tablet {
    min-width: min-content; // THIS ONE
    height: 80vh;
  }

  @include desktop {
    // min-width: 50rem; // THIS ONE
    // width:min-content;
    // min-width: 42%;
    // width: -webkit-fit-content;
    min-width: 40rem; // THIS ONE
  }
}

.shelter-list-og {
  display: block;
  letter-spacing: 0.04rem;

  @include mobile {
    letter-spacing: 0.02rem;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  @include desktop {
    // margin: 0 10rem; // testing mobile layout on desktop
  }
}

.shelter-item-og {
  display: flex;
  border-bottom: 4px solid; // Visual seperator (vertical)
  border-image: radial-gradient(
    75% 75% at 50% 50%,
    #e3e9f3 100%,
    #073aff00 0%
  ); // Visual seperator (vertical)
  border-image-slice: 1; // Visual seperator (vertical)
  background-color: rgb(31 27 27);
  // width: 100%;
  height: 100%;
  z-index: 9;
  backdrop-filter: blur(5px);
  transition: all 1s ease-in-out;

  &:hover {
    background-image: linear-gradient(
      147deg,
      #c3cbdc24 23%,
      #80808016 10% #e0f2ff24 66%
    );
    transition: all 1s ease-in-out;
  }

  @include mobile {
    padding: 1rem;
  }

  @include tablet {
    padding: 0.5rem;
  }

  @include desktop {
    padding: 0.5rem;
  }
}

.shelter-item__content-og {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @include mobile {
    display: grid;
    line-height: 1.2rem;
    justify-content: space-around;
    width: 24.5rem;

    // width: 64rem; // CULPRIT #4 - change to  100% fix?
  }
  @include tablet {
    justify-content: space-evenly;
    width: 100%;
  }
}

// ----------------- SHELTER ITEM -- LEFT -----------------

.shelter-item__left-og {
  // DO NOT CHANGE
  display: flex;
  flex-direction: column;
  width: 50%;
  // min-width: 19rem;
  padding: 1rem;
  // DO NOT CHANGE
  line-height: 2rem;
  min-width: -webkit-min-content;
  max-width: -webkit-fit-content;

  @include mobile {
    // min-width: -webkit-fill-available;
  }

  @include tablet {
    width: 35%;
    margin-left: 1rem;
  }

  @include desktop {
    width: 46%;
  }
}

.shelter-item__left-inner-og {
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
}

.shelter-item__title-og {
  display: flex;
  justify-content: center;

  @include mobile {
    padding-bottom: 2rem;
    line-height: 2rem;
  }
}

.shelter-item__text-og {
  flex-basis: min-content;
  padding: 1rem;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-family: 'Toronto Subway';
  border-radius: 5px;
  transition: all 1s ease-in-out;

  &:hover {
    text-shadow: 0 2px 4px rgba(229, 228, 255, 0.7);
  }

  @include mobile {
    font-size: 1.1rem; // CULPRIT #5 - fixed
    line-height: 1.5;
    align-items: center;
    padding: 0.5rem 0rem;
  }
  @include tablet {
    font-size: 1.4rem;
    line-height: 2rem;
    min-width: 30rem;
    align-items: center;
    gap: 0.5rem;
  }
  @include desktop {
    letter-spacing: 0.8px;
    font-size: 2.9rem;
    line-height: 2.25rem;
    min-width: 22rem;
    font-size: 1.5vw; // testing mobile layout on desktop
    // min-width: max-content; // testing mobile layout on desktop
    width: 100%; // testing mobile layout on desktop
  }
}

.shelter-item__details-og {
  padding: 0.6rem;
  background-image: linear-gradient(135deg, #6d5dfc 0%, #c692ecdd 100%);
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;

  @include desktop {
    min-width: fit-content;
  }
}

.shelter-item__availability-og {
  color: white;
  font-size: 0.88rem;
  line-height: 1.6;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2.1rem;
  font-family: 'League Spartan Regular';
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;

  @include mobile {
    font-size: 0.8rem;
  }

  @include tablet {
    font-size: 0.8rem;
    min-width: max-content;
  }

  @include desktop {
    min-width: max-content;
    max-width: 30rem;
  }
}

.shelter-item__divider-og {
  height: 100%;
  align-self: center;
}

// ----------------- SHELTER ITEM -- RIGHT -----------------

.shelter-item__right-og {
  // DO NOT CHANGE
  display: flex;
  flex-direction: column;
  padding: 1rem;
  // DO NOT CHANGE

  @include mobile {
    flex-direction: row;
  }

  @include tablet {
    // min-width: -webkit-fit-content;
    width: 15rem;
    margin-right: 1rem;
    font-variant-caps: all-petite-caps;
  }

  @include desktop {
    // min-width: -webkit-fit-content;
    width: 15rem;
    line-height: 1.6rem;
    font-variant-caps: all-petite-caps;
  }
}

.shelter-item__right-inner-og {
  box-shadow: 1px -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @include mobile {
    font-size: 1.01rem;
    width: 100%;
  }

  @include tablet {
    font-size: 1.1rem;
    width: 100%;
  }

  @include desktop {
    font-size: 1rem;
    backdrop-filter: contrast(0.5); // desktop only
    margin-top: 0.7rem; // desktop only
    padding: 0.6rem;

    // @include desktop { // Working Hover Effect 'Folder/Floating Cards'
    //   font-size: 1rem;
    //   background-color: darkgray;
    //   backdrop-filter: contrast(0.1); // desktop only
    //   padding: 0.6rem;
    //   margin: 0rem;
    //   box-shadow: 0px -18px 10px rgb(17 17 17 / 69%);
    //   transition: padding 0.69s 0.1s ease-in-out,
    //               margin 1.1s 0.001s ease-in-out,
    //               box-shadow 0.7s ease-in-out,
    //               transform 0.03s 0.001s ease-in-out;

    //   &:hover {
    //     margin-top: -2rem; // desktop only
    //     margin-bottom: 1.7rem;
    //     padding: 2rem 0.6rem;
    //     // box-shadow: none;
    //     transform: scale(1.05); // Correctly using CSS transform for zoom
    //   }

    // }
  }
}

.shelter-item__right-title-og {
  color: white;
  margin-bottom: 0.6rem;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @include mobile {
    font-size: 0.85rem;
  }

  @include tablet {
    font-size: 0.85rem;
  }

  @include desktop {
    font-size: 1rem;
  }
}

.shelter-item__right-text-og {
  font-weight: 600;
  max-width: fit-content;
  color: #2a445e;
  color: white;
  margin-left: 0.7rem;
  padding-left: 0.7rem;
  border-left: #7e67f9 solid 2px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  font-size: 1.2rem;
  font-family: 'Toronto Subway Bold';

  @include mobile {
    font-family: 'League Spartan Light';
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.3rem;
  }

  @include tablet {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  @include desktop {
    // font-size: 0.85rem;
  }
}

.scroll-instructions-og {
  @include mobile {
    display: none;
  }

  @include tablet {
    display: none;
  }

  @include desktop {
    display: none;
    color: white;
    display: flex;
    width: 40rem;
    justify-content: center;
    font-variant: all-petite-caps;
    font-size: 1.1rem;
    padding-top: 1rem;
    letter-spacing: 0.5px;
  }
}
// ----------------- DETAILED VIEW (MOBILE)-----------------

.mobile__shelter-scrollable-container-og {
  height: auto;

  @include mobile {
    width: auto;
  }

  @include tablet {
  }

  @include desktop {
    // display: none;
  }
}

.mobile__instructions-text-og {
  color: white;
  font-style: italic;
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.1rem;
  font-style: italic;
  gap: 1rem;
}

.mobile__shelter-item-og {
  justify-content: center;

  &:hover {
    background-color: darken(#898e99, 15%);
    // transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    // padding-top: 4rem;
    transition: all 0.4s ease-in-out;
  }
}

.mobile__shelter-item__content-og {
  @include mobile {
    width: 95vw;
  }

  @include desktop {
    width: 95%; // testing mobile layout on desktop
    justify-content: space-between; // testing mobile layout on desktop
  }
}

.shelter-item__location-glance-og {
  @include mobile {
    font-size: 0.8rem;
  }

  @include tablet {
    font-size: 0.8rem;
  }

  @include desktop {
    font-size: 0.8rem;
  }
}

.mobile__shelter-item__availability-og {
  padding: 0.6rem;
  background-image: linear-gradient(135deg, #6d5dfc 0%, #c692ecdd 100%);
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 95%;
  font-family: 'Avenir Next';
  font-style: italic;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 1.5rem;

  @include mobile {
    font-size: 0.65rem;
    width: 90vw;
    line-height: 1.3rem;
    margin: 0;
  }

  @include tablet {
    width: 100%;
    letter-spacing: normal;
    font-size: 0.75rem;
  }

  @include desktop {
    width: 50%;
  }
}

.shelter-detailed-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  // animation: fadeIn 0.5s ease-out forwards;

  @include desktop {
    // display: none;
  }
}

.back-button-og {
  width: 100%;
  height: 9rem;
  font-family: 'Toronto Subway';
  font-weight: 600;
  font-size: 1.2rem;
  z-index: 1002;
  gap: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: white;
  border-radius: 0;
  cursor: pointer;
  border: none;

  &:active {
    background-color: black;
    color: white;
    transition: all 0.3s ease;
  }

  @include desktop {
    // testing mobile layout on desktop
    &:hover {
      background-color: rgb(0, 0, 0);
      color: white;
      transition: all 0.3s ease;
    }
  }
}

.detailed-view__header-og {
  color: white;
  padding: 0.75rem;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  display: flex;
  background-color: black;

  place-content: center;

  width: 100%;
  // background-color: #2a445e;
  letter-spacing: 2px;

  @include mobile {
    font-size: 0.85rem;
  }

  @include tablet {
    font-size: 0.85rem;
  }

  @include desktop {
    font-size: 1.3rem;
  }
}

.detailed-view__text-container-og {
  display: inline-flex;
  flex-direction: row;
  margin-top: 1rem;

  @include mobile {
    display: flex;
    justify-content: center;
  }

  @include tablet {
    display: flex;
    justify-content: center;
  }
}
.location-text-container-og {
  background-color: rgba(0, 0, 0, 0.8);
  width: -webkit-fill-available;
}

.location-text-og {
  color: white;
  font-size: 1.3rem;
  font-family: 'Toronto Subway Bold';
  text-align: center;
  // margin-bottom: 1rem;
  display: flex;
  padding: 1rem 1rem 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// ----------------- NEW JUNE 2024 STANDARDIZED VIEW (Across Devices)-----------------

.ant-pagination-item a {
  color: white !important;

  &:hover {
    color: #1677ff !important;
    background-color: white;
  }
}

.ant-pagination-item-active a {
  color: #1677ff !important;
}

.ant-pagination {
  color: white !important;
  display: flex;
  justify-content: center;
}

.ant-pagination-item-link {
  color: white !important;
}

.ant-select-selector {
  cursor: pointer;
}

.ant-pagination-item-ellipsis {
  color: white !important;
  line-height: 4;
}

.ant-spin-spinning {
  position: static;
  display: block;
  opacity: 1;
  padding: 10rem;
}
